<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Pagos realizados">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
        <Basic-Btn text="Agregar pago" color="secondary" icon="mdi-cash-plus" @click="dialogs.file = true" :loading="loading" />
        <Basic-Btn text="Convenio" color="primary" icon="mdi-calendar-plus" @click="dialogs.agreement = true" :loading="loading" :disabled='items.length > 0 ? !(items[0].semana > 12) : true'/>
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="yellow darken-1" indeterminate></v-progress-linear>
        <template v-slot:[`item.monto_entregado`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_entregado) }}
        </template>
        <template v-slot:[`item.monto_multa`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_multa) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getDetail(item.id)">mdi-eye</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.file" title="Registrar pago">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="addFile()" :loading="loading" />
      </template>
      <v-container>
        <v-row>
          <!-- <v-col cols="6" class="text-left"> <b>Nombre: </b> {{ requestFileUse.name }} </v-col>
          <v-col cols="6" class="text-right"> <b>Estatus: </b> {{ returnRequestState(requestFileUse.status.length > 0 ? requestFileUse.status[0].estatus.nombre : "no disponibe") }} </v-col> -->
        </v-row>
      </v-container>
      <v-form ref="formFile" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.file.metodo_pago" :items="fileTypes" hide-no-data hide-selected item-text="text" item-value="value" label="Metodo de pago" prepend-icon="mdi-file-document-outline" :loading="loading" outlined dense outlined dense required></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model.number="forms.file.monto" label="Monto" prepend-icon="mdi-cash" :disabled="loading" type="number" min="0.5" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12" v-if="forms.file.metodo_pago == 'TRANSFERENCIA' || forms.file.metodo_pago == 'DEPOSITO'">
            <v-text-field v-model="forms.file.cuenta_deposito" label="Cuenta bancaria" prepend-icon="mdi-smart-card" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12" v-if="forms.file.metodo_pago == 'TRANSFERENCIA' || forms.file.metodo_pago == 'DEPOSITO'">
            <v-text-field v-model="forms.file.referencia_deposito" label="Referencia de autorización" prepend-icon="mdi-credit-card" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12" v-if="forms.file.metodo_pago == 'TRANSFERENCIA' || forms.file.metodo_pago == 'DEPOSITO'">
            <v-file-input v-model="forms.file.file" label="Archivo" prepend-icon="mdi-card-account-details" show-size :loading="loading" outlined dense @change="onFileChanged"></v-file-input>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea v-model="forms.file.comentarios" label="Comentarios" prepend-icon="mdi-comment-text-outline" :disabled="loading" outlined dense></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.detail" title="Detalle de pago">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
      </template>
      <v-data-table :headers="headersDetail" :items="detail" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading || !item.comprobante_deposito" @click="viewerFile(item), downloadReceipt(item)">mdi-eye</v-icon></template
            ><span>Ver comprobante</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.viewFile" title="Comprobarte de deposito">
      <template v-slot:buttons>
        <Basic-Btn text="Cerrar" color="error" icon="mdi-close" @click="dialogs.viewFile = false" :loading="loading" />
      </template>

        <Magnifier :src="img" :src-large="img" />
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.agreement" title="Agregar convenio">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="saveAgreement()" :loading="loading" />
      </template>
      <v-form ref="formAgreement" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model.number="forms.agreement.monto" label="Monto" prepend-inner-icon="mdi-currency-usd" prepend-icon="mdi-cash" :disabled="loading" type="number" step="0.01" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-combobox :items="weekends" v-model="forms.agreement.semanas" label="Número de semanas" prepend-icon="mdi-calendar-blank" :disabled="loading" outlined dense required></v-combobox>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn, DialogForm, Magnifier } from "@/components";
import downloadjs from "downloadjs";

export default {
  name: "pays",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DialogForm,
    Magnifier,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    img: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Semana", align: "left", sortable: true, value: "semana" },
      { text: "Monto entregado", align: "left", sortable: true, value: "monto_entregado" },
      { text: "Monto de multa", align: "left", sortable: true, value: "monto_multa" },
      { text: "Fecha de creación", align: "left", sortable: true, value: "fecha_creacion" },
      { text: "Fecha de fin", align: "left", sortable: true, value: "fecha_fin" },
      { text: "Comentarios", align: "left", sortable: true, value: "comentarios" },
      { text: "Acciones", align: "left", sortable: true, value: "actions" },
    ],
    headersDetail: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Monto", align: "left", sortable: true, value: "monto" },
      { text: "Cuenta de deposito", align: "left", sortable: true, value: "cuenta_deposito" },
      { text: "Referencia de deposito", align: "left", sortable: true, value: "referencia_deposito" },
      { text: "Comentarios", align: "left", sortable: true, value: "comentarios" },
      { text: "Fecha de creación", align: "left", sortable: true, value: "fecha_creacion" },
      { text: "Ver comprobante", align: "left", sortable: true, value: "actions" },
    ],
    items: [],
    detail: [],
    general: {},
    detailItem: {},
    forms: {
      file: {
        solicitud: null,
        monto: null,
        metodo_pago: null,
        cuenta_deposito: null,
        referencia_deposito: null,
        comentarios: null,
        file: null,
      },
      agreement: {
        id: null,
        monto: 0,
        semanas: 0
      },
    },
    fileTypes: [
      {
        text: "Efectivo",
        value: "EFECTIVO",
      },
      {
        text: "Transferencia",
        value: "TRANSFERENCIA",
      },
      {
        text: "Deposito",
        value: "DEPOSITO",
      },
      {
        text: "Pago cubierto",
        value: "PAGO_CUBIERTO",
      },
    ],
    dialogs: {
      file: false,
      detail: false,
      viewFile: false,
      agreement: false,
    },
    weekends: [1, 2, 3, 4],
    formats: ["image/jpeg", "image/jpg", "image/png", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/pdf", "application/octet-stream"],
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      let request = {
        params: {
          solicitud: this.$route.params.id,
        },
      };
      this.axios
        .get(services.routes.payment, request)
        .then((response) => {
          let data = response.data.data;
          this.general = data.general;
          this.items = data.semanas;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFileChanged(e) {
      this.forms.file.file = e.target.files[0];
    },
    addFile() {
      let valid = this.forms.file.metodo_pago == "TRANSFERENCIA" || this.forms.file.metodo_pago == "DEPOSITO";
      if (!valid ? true : this.forms.file.file.size <= 1000000) {
        if (!valid ? true : this.validFormat(this.forms.file.file.type)) {
          this.loading = true;
          let info = new FormData();
          this.forms.file.solicitud = this.$route.params.id;
          for (let key in this.forms.file) {
            info.append(key, this.forms.file[key]);
          }
          this.axios
            .post(services.routes.payment, info)
            .then((response) => {
              this.refresh();
            })
            .catch((error) => {
              this.alert.active = true;
              this.alert.color = "error";
              this.alert.msg = error;
            })
            .finally(() => {
              this.loading = false;
              this.alert.active = true;
              this.alert.color = "success";
              this.alert.msg = "Comprobante agregado correctamente";
              this.selectMenuAction = 2;
            });
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Formato no valido.";
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Documento supera el limite de 1MB.";
      }
    },
    validFormat(format) {
      let valid = false;
      for (let type of this.formats) {
        if (format == type) {
          valid = true;
        }
      }
      return valid;
    },
    cancel() {
      if (this.dialogs.file) {
        this.$refs.formFile.reset();
        for (let key in this.forms.file) {
          this.forms.file[key] = null;
        }
        this.$refs.formFile.resetValidation();
      }else if(this.dialogs.agreement){
        this.$refs.formAgreement.reset();
        for (let key in this.forms.agreement) {
          this.forms.agreement[key] = null;
        }
        this.$refs.formAgreement.resetValidation();
      }
      this.dialogs.agreement = false;
      this.dialogs.file = false;
      this.dialogs.detail = false;
  },
    async getDetail(id) {
      this.dialogs.detail = true;
      this.loading = true;
      let request = {
        params: {
          id: id,
        },
      };
      this.axios
        .get(services.routes.payment + "/detail", request)
        .then((response) => {
          this.detail = response.data.data;
          this.dialogs.detail = true;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadReceipt(data) {
      // this.animation = "downloader";
      this.loading = true;
      let url = services.routes.payment;
      this.axios({
        method: "get",
        url: url + "/detail/receipt?id=" + data.id + "&codigo=" + data.comprobante_deposito,
        responseType: "blob",
      })
        .then((response) => {
          let fileName = response.headers["content-disposition"].split("attachment; filename=");
          // downloadjs(response.data, fileName[1]);
          var src = URL.createObjectURL(response.data);
          this.img = src;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          // this.animation = "loader";
        });
    },
    viewerFile(item) {
      this.dialogs.viewFile = true;
    },
    saveAgreement() {
      this.forms.agreement.id = parseInt(this.$route.params.id, 10);
      if (this.$refs.formAgreement.validate()) {
        let data = JSON.parse(JSON.stringify(this.forms.agreement));
        this.axios
          .post(services.routes.agreement, { data })
          .then(() => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Convenio creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
  },
};
</script>

<style scoped></style>
